<template>
  <router-link :to="path" class="simple-btn">
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  props: {
    path: {
      type: String,
      default: '/',
      required: true
    }
  },
  name: 'MyRouterLink'
}
</script>

<style scoped></style>
